import { generateUUID } from 'components/common/utils/helpers';
import { useState } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cross,
  Label,
} from 'recharts'

import ActionTabs from './ActionTabs'

import styled from 'styled-components'
import { device } from 'components/common/constants/device'

const infoList = [
  // { color: '#1F64EA', title: 'Clicks' },
  { color: '#1FB9EA', title: 'User Session' },
  { color: '#FF9900', title: 'Conversions' },
  { color: '#00BD13', title: 'Profit' },
]

const Wrap = styled.div`
  position: relative;
  width: ${({ full }) => (full ? '100%' : '880px')};
  height: 448px;
  padding: 16px;
  background: var(--white);
  border: 1px solid #e7eadd;

  .chart-container {
    padding: 0 20px;
  }

  @media ${device.mobile} {
    width: 100%;
    height: 293px;
    margin-right: 16px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled.h2`
  text-transform: uppercase;
`

const Resize = styled.div`
  cursor: pointer;
`

const BtnWrap = styled.div`
  width: fit-content;
  margin: 0 auto 16px auto;

  @media ${device.mobile} {
    margin: 8px auto 0 auto;
  }
`

const InfoWrap = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: end;
  gap: 27px;
  margin-top: 20px;

  @media ${device.mobile} {
    justify-content: start;
    row-gap: 8px;
  }
`

const InfoItem = styled.li`
  display: flex;
  align-items: center;
  gap: 10px;
`

const InfoLine = styled.div`
  width: 12px;
  height: 4px;
  background-color: ${({ color }) => color};
`

const InfoText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`

// using Customized gives you access to all relevant chart props
const CustomizedCross = ({ width, fill, formattedGraphicalItems }) => {
  // get first series in chart
  const firstSeries = formattedGraphicalItems[0]
  // get any point at any index in chart
  const secondPoint = firstSeries?.props?.points[1]

  // render custom content using points from the graph
  return (
    <Cross
      y={secondPoint?.y}
      x={secondPoint?.x}
      top={5}
      left={50}
      height={464}
      width={width}
      fill={fill ?? 'none'}
    />
  )
}

const Diagram = ({
  data,
  resize,
  handleChange,
  initialValue,
  daysChoosed,
  defaultRanger = ['day'],
  grafickHeight = '76%',
  minimalInterface,
  isFullscrin = false,
}) => {
  const [isDiagramFullscrin, setisDiagramFullscrin] = useState(isFullscrin)
  const lasValue = typeof daysChoosed === 'number' ? ['day'] : defaultRanger

  const tabsData =
    daysChoosed > 30
      ? ['year', 'month', 'week', 'day']
      : daysChoosed > 7
      ? ['month', 'week', 'day']
      : daysChoosed > 0
      ? ['week', 'day']
      : lasValue

  if (minimalInterface) {
    return (
      <Wrap>
        <Header>
          <Title>statistics</Title>
        </Header>
        <BtnWrap>
          <ActionTabs
            data={tabsData}
            handleChange={(v) => {
              if (handleChange) handleChange(v)
            }}
            initialValue={initialValue}
          />
        </BtnWrap>

        <ResponsiveContainer width="100%" height={grafickHeight}>
          <LineChart data={data}>
            <Label />
            <Tooltip />
            {/*<Line*/}
            {/*  strokeWidth={3}*/}
            {/*  type="cardinal"*/}
            {/*  dataKey="clicks"*/}
            {/*  stroke="#1F64EA"*/}
            {/*/>*/}
            <Line
              strokeWidth={3}
              type="cardinal"
              dataKey="user session"
              stroke="#1FB9EA"
            />
            <Line
              strokeWidth={3}
              type="cardinal"
              dataKey="conversions"
              stroke="#FF9900"
            />
            <Line
              strokeWidth={3}
              type="cardinal"
              dataKey="profit"
              stroke="#00BD13"
            />
          </LineChart>
        </ResponsiveContainer>
        <InfoWrap>
          {infoList.map(({ color, title }) => (
            <InfoItem key={generateUUID()}>
              <InfoLine color={color} />
              <InfoText>{title}</InfoText>
            </InfoItem>
          ))}
        </InfoWrap>
      </Wrap>
    )
  }

  return (
    <Wrap full={isDiagramFullscrin}>
      <Header>
        <Title>statistics</Title>
        {resize && (
          <Resize onClick={() => setisDiagramFullscrin(!isDiagramFullscrin)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21 4.5V19.5M3 4.5V19.5"
                stroke="#2F455C"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3 12H10.5M10.5 12L8 9.5M10.5 12L8 14.5"
                stroke="#2F455C"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21 11.9998L14.25 11.9998M14.25 11.9998L16.75 14.4998M14.25 11.9998L16.75 9.49976"
                stroke="#2F455C"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Resize>
        )}
      </Header>
      <BtnWrap>
        <ActionTabs
          data={tabsData}
          handleChange={(v) => {
            if (handleChange) handleChange(v)
          }}
          initialValue={initialValue}
        />
      </BtnWrap>

      <ResponsiveContainer width="100%" height={grafickHeight}>
        <LineChart data={data}>
          <CartesianGrid
            stroke="var(--bright)"
            horizontal
            vertical={false}
            strokeWidth={1}
            strokeDasharray="0 0"
          />
          <XAxis
            stroke="var(--bright)"
            dataKey="date"
            tickLine={false}
            tick={({ x, y, payload }) => (
              <g transform={`translate(${x},${y})`}>
                <rect
                  x="-2"
                  y="-28"
                  width="5"
                  height="5"
                  fill="#0B1C3E"
                  rx="10"
                />
                <text
                  x={-30}
                  y={0}
                  dy={6}
                  fontSize={13}
                  fontWeight={500}
                  fill="#666"
                >
                  {payload.value}
                </text>
              </g>
            )}
            // interval={0}
            // textAnchor="end"
            // tickFormatter={(value) => value.toUpperCase()}
            tickMargin={20}
          />
          <YAxis
            tickMargin={16}
            // domain={'auto'}
            // domain={[1300, 26900]}
            // domain={[1300, 27000]}
            tickCount={10}
            axisLine={false}
            tick={{ fontSize: 13, fontWeight: 500, className: 'my-tick' }}
            tickLine={false}
          />
          <Label />
          <Tooltip />
          {/*<Line*/}
          {/*  strokeWidth={3}*/}
          {/*  type="cardinal"*/}
          {/*  dataKey="clicks"*/}
          {/*  stroke="#1F64EA"*/}
          {/*/>*/}
          <Line
            strokeWidth={3}
            type="cardinal"
            dataKey="user session"
            stroke="#1FB9EA"
          />
          <Line
            strokeWidth={3}
            type="cardinal"
            dataKey="conversions"
            stroke="#FF9900"
          />
          <Line
            strokeWidth={3}
            type="cardinal"
            dataKey="profit"
            stroke="#00BD13"
          />
          {/* <Customized component={CustomizedCross} width="3" /> */}
        </LineChart>
      </ResponsiveContainer>
      <InfoWrap>
        {infoList.map(({ color, title }) => (
          <InfoItem key={generateUUID()}>
            <InfoLine color={color} />
            <InfoText>{title}</InfoText>
          </InfoItem>
        ))}
      </InfoWrap>
    </Wrap>
  )
}

export default Diagram
