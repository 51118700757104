import styled from 'styled-components'
import { device } from 'components/common/constants/device'

export const TH = styled.th`
  position: relative;
  padding: 8px 16px;
  width: ${({ width }) => width};

  border: ${({ bordernone }) =>
    bordernone ? 'none' : '1px solid var(--bright)'};
  text-align: ${({ align }) => (align ? align : 'start')};
  z-index: ${({ zInd }) => zInd};
  white-space: ${({ nowrap }) => nowrap && `nowrap;`};
  cursor: ${({ cursor }) => cursor};

  ::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    box-shadow: ${({ lineoff }) => (lineoff ? 'none' : '0px 4px #d7dff6')};
  }

  @media ${device.mobile} {
    padding: 8px;
  }
`

export const TD = styled.td`
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding || '6px 16px'};
  text-align: ${({ align }) => (align ? align : 'start')};
  cursor: ${({ cursor }) => cursor};
  color: ${({ color }) => color && `var(--${color})`};
  white-space: ${({ nowrap }) => nowrap && `nowrap;`};
  overflow: ${({ overflow }) => overflow};

  border: ${({ bordernone }) =>
    bordernone ? 'none' : '1px solid var(--bright)'};
  position: ${({ line }) => line && 'relative'};

  ::before {
    content: '';
    display: ${({ line }) => (line ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    border-left: ${({ line }) => `4px solid var(--${line})`};
  }

  @media ${device.mobile} {
    padding: 4px 8px;
  }
`

export const SortWrap = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
`

export const StatusWrap = styled.div`
  width: fit-content;
  margin: auto;
  padding: 4px 6px;

  color: var(--white);
  background: ${({ status }) => {
    if (status === 'Active') return 'var(--status-success)'
    if (status === 'Accepted') return 'var(--status-success)'
    if (status === 'Approved') return 'var(--status-success)'
    if (status === 'Success') return 'var(--status-success)'
    if (status === 'Done') return 'var(--status-success)'

    if (status === 'Pending') return 'var(--status-warning)'
    if (status === 'Waiting') return 'var(--status-warning)'
    if (status === 'Hold') return 'var(--status-warning)'
    if (status === 'Partly Pay') return 'var(--status-warning)'
    if (status === 'Paused') return 'var(--status-warning)'
    if (status === 'On Moderation') return 'var(--status-warning)'
    if (status === 'Moderation') return 'var(--status-warning)'

    if (status === 'Declined') return 'var(--status-error)'
    if (status === 'Deleted') return 'var(--status-error)'
    if (status === 'Banned') return 'var(--status-error)'
    if (status === 'Stopped') return 'var(--status-error)'

    if (status === 'Traffic Back') return 'var(--status-critical)'
    if (status === 'Traffic back') return 'var(--status-critical)'
    if (status === 'Fraud') return 'var(--status-critical)'

    if (status === 'Not Active') return 'var(--status-inactive)'

    return 'var(--status-inactive)'
  }};

  @media ${device.mobile} {
    margin-left: auto;
    margin-right: 0;
  }
`
