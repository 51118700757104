// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.glow-on-hover {
  border: none;
  outline: none;
  background: #000000;
  position: relative;
  z-index: 0;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(
    90deg,
    var(--white),
    var(--primary-2),
    var(--white),
    var(--primary-2),
    var(--white),
    var(--primary-2),
    var(--white)
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 30s linear infinite;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.glow-on-hover:hover {
  color: #000;
}

.glow-on-hover:hover:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--primary-2);
  left: 0;
  top: 0;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/btn-animate.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,WAAW;EACX;;;;;;;;;GASC;EACD,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,qBAAqB;EACrB,WAAW;EACX,iBAAiB;EACjB,uBAAuB;EACvB,wBAAwB;EACxB,sCAAsC;EACtC,UAAU;EACV,oCAAoC;AACtC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,4BAA4B;EAC5B,OAAO;EACP,MAAM;AACR;;AAEA;EACE;IACE,wBAAwB;EAC1B;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".glow-on-hover {\n  border: none;\n  outline: none;\n  background: #000000;\n  position: relative;\n  z-index: 0;\n}\n\n.glow-on-hover:before {\n  content: '';\n  background: linear-gradient(\n    90deg,\n    var(--white),\n    var(--primary-2),\n    var(--white),\n    var(--primary-2),\n    var(--white),\n    var(--primary-2),\n    var(--white)\n  );\n  position: absolute;\n  top: -2px;\n  left: -2px;\n  background-size: 400%;\n  z-index: -1;\n  filter: blur(5px);\n  width: calc(100% + 4px);\n  height: calc(100% + 4px);\n  animation: glowing 30s linear infinite;\n  opacity: 1;\n  transition: opacity 0.3s ease-in-out;\n}\n\n.glow-on-hover:hover {\n  color: #000;\n}\n\n.glow-on-hover:hover:after {\n  background: transparent;\n}\n\n.glow-on-hover:hover:before {\n  opacity: 1;\n}\n\n.glow-on-hover:after {\n  z-index: -1;\n  content: '';\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background: var(--primary-2);\n  left: 0;\n  top: 0;\n}\n\n@keyframes glowing {\n  0% {\n    background-position: 0 0;\n  }\n\n  50% {\n    background-position: 400% 0;\n  }\n\n  100% {\n    background-position: 0 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
