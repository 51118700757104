import styled from 'styled-components'
import iconPlus from 'images/icons/plus.svg'
import iconMinus from 'images/icons/minus.svg'
import { useEffect, useState } from 'react'

const Wrap = styled.div`
  position: fixed;
  z-index: 100;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  gap: 8px;

  animation: fadeIn 2s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, 15px);
    }
    to {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  background-color: var(--primary-2);
`

const Zoomable = () => {
  const [scale, setScale] = useState(1)
  const zommElement = window.document.querySelectorAll('.zoomble')

  useEffect(() => {
    if (!zommElement && !zommElement.length) return

    for (let el of zommElement) {
      el.style.transformOrigin = 'top left'
      el.style.transform = `scale(${scale})`
    }

  }, [scale])

  const handleZoomIn = () => setScale((prev) => Math.min(prev + 0.1, 2))
  const handleZoomOut = () => setScale((prev) => Math.max(prev - 0.1, 0.5))

  return <Wrap>
    <Button onClick={handleZoomOut}><img src={iconMinus} alt={'minus'} /></Button>
    <Button onClick={handleZoomIn}><img src={iconPlus} alt={'plus'} /></Button>
  </Wrap>
}

export default Zoomable


