import styled from 'styled-components'

import groupby from 'images/icons/groupby.svg'
import filter from 'images/icons/filter.svg'
import download from 'images/icons/download.svg'
import setting from 'images/icons/setting.svg'
import eye from 'images/icons/eye.svg'
import loupe from 'images/icons/loupe.svg'
import loupeWhite from 'images/icons/loop-white.svg'
import rubbish from 'images/icons/rubbish.svg'
import rubbishGray from 'images/icons/rubbish-gray.svg'
import cross from 'images/icons/cross.svg'

const iconsList = {
  groupby,
  filter,
  download,
  setting,
  eye,
  loupe,
  loupeWhite,
  rubbish,
  rubbishGray,
  cross
}

const Wrap = styled.div`
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  right: ${({ right }) => right};

  display: ${({ display }) => display};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};

  width: ${({ width }) => width};
  margin-top: ${({ mt }) => mt};
  margin-bottom: ${({ mb }) => mb};
  margin-left: ${({ ml }) => ml};
  margin-right: ${({ mr }) => mr};

  z-index: ${({ zInd }) => zInd};
`

const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: ${({ iconOnly, iconWidth, width }) =>
    iconOnly
      ? '36px'
      : iconWidth
        ? iconWidth
        : width === '100%'
          ? '100%'
          : 'fit-content'};
  height: ${({ heightoff }) => (heightoff ? 'auto' : '36px')};
  padding: ${({ paddingNone, icon }) =>
    paddingNone ? 'none' : icon ? '6px 16px' : '9px 16px'};
  background: ${({ blue, bgNone }) =>
    blue ? 'var(--primary-2)' : bgNone ? 'none' : 'var(--white)'};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ borderRadius }) => borderRadius};

  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  font-size: ${({ size }) => (size ? `${size}px` : '14px')};
  color: ${({ color }) => (color ? `var(--${color})` : 'var(--primary-1)')};

  //outline: ${({ blue, bgNone }) => `1px solid ${blue ? 'var(--primary-2)' : bgNone ? 'none' : 'var(--white)'}}`};

  :hover {
    outline: ${({ hoverOff }) => (hoverOff ? 'none' : 'var(--hover-btns)')};
  }
`

const Button = ({
  text,
  handleClick,
  blue,
  display,
  align,
  justify,
  position,
  top,
  bottom,
  right,
  width,
  marginT,
  marginB,
  marginL,
  marginR,
  backgroundColor,
  borderRadius,
  iconWidth,
  bgNone,

  disabled,
  type = 'button',
  paddingNone,
  size,
  icon,
  iconOnly,
  color,
  heightoff,
  hoverOff,
  exportUrl,
  testid,
  zInd,

  animated
}) => {
  const saveDataToExcel = async () => {
    try {
      const csvData = await exportUrl()

      const fileName = prompt('Enter file name:', 'example')
      if (!fileName) {
        throw new Error('Canceled.')
      }

      // Create object Blob
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })

      // Create link from download file
      const link = document.createElement('a')
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', fileName + '.csv')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        throw new Error('The browser does not support the file saving feature.')
      }
    } catch (error) {
      console.error('Error saving data:', error)
    }
  }

  return (
    <Wrap
      position={position}
      top={top}
      bottom={bottom}
      right={right}
      width={width}
      mt={marginT}
      mb={marginB}
      ml={marginL}
      mr={marginR}
      align={align}
      display={display}
      justify={justify}
      zInd={zInd}
    >
      <Btn
        borderRadius={borderRadius}
        backgroundColor={backgroundColor}
        iconWidth={iconWidth}
        data-testid={testid}
        width={width}
        blue={blue}
        type={type}
        size={size}
        disabled={disabled}
        bgNone={bgNone}
        onClick={
          icon === 'download' ? () => saveDataToExcel(exportUrl) : handleClick
        }
        paddingNone={paddingNone}
        icon={icon}
        iconOnly={iconOnly}
        color={color}
        heightoff={heightoff}
        hoverOff={hoverOff}
        className={animated && 'glow-on-hover'}
      >
        {icon && (
          <img
            alt={icon}
            src={iconsList[icon]}
            width={iconOnly ? '20' : '24'}
            height={iconOnly ? '20' : '24'}
          />
        )}
        {text}
      </Btn>
    </Wrap>
  )
}

export default Button
