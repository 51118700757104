import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import UserRoutes from './Routes/UserRoutes'

const titles = {
  '/dashboard': 'Dashboard',
  '/roles/affiliate': 'Affiliates',
  '/roles/advertiser': 'Advertisers',
  '/roles/manager': 'Managers',
  '/distribution/re-distribution': 'Re-Cold API',
  '/distribution/lead-list': 'Lead-List',
  '/billing': 'Billing',
  '/landings': 'Landings',
  '/offers': 'Offers',
  '/offers/add-direct-link': 's2s',
  '/offers/add-api': 'Api',
}

function App() {
  const location = useLocation()

  useEffect(() => {
    const customTitle = titles[location.pathname]

    if (customTitle)
      document.title = `${process.env.REACT_APP_PARTNER_TITLE} | ${
        titles[location.pathname]
      }`
  }, [location])

  return <UserRoutes />
}

export default App
