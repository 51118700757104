import { generateUUID } from 'components/common/utils/helpers';
import { NavLink, useLocation } from 'react-router-dom'

import styled from 'styled-components'
import { device } from 'components/common/constants/device'

const List = styled.div`
  display: flex;
  align-items: center;

  margin-left: ${({ marginleft }) => marginleft};
  margin-bottom: ${({ marginBot }) => marginBot};

  padding-right: ${({ paddingRight }) => paddingRight};

  overflow: scroll;

  @media ${device.mobile} {
    padding-left: 16px;
    overflow: scroll;
  }
`

const Item = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: ${({ minwidth }) => (minwidth ? minwidth : '128px')};
  height: 36px;
  padding: 0 16px;
  background: var(--white);
  cursor: pointer;
  border: 1px solid var(--white);

  &:hover {
    border: var(--hover-btns);
  }

  &.active {
    background: var(--primary-2);
    border: 1px solid var(--primary-2);
  }
`

const Text = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: var(--primary-1);
`

const Tabs = ({ tabsList, marginLeft, mb, paddingRight, minWidth }) => {
  const { pathname } = useLocation()

  const handleClick = (event, link) => {
    if (link === pathname) {
      event.preventDefault()
      window.location.href = link
    }
  }

  return (
    <List marginBot={mb} marginleft={marginLeft} paddingRight={paddingRight}>
      {tabsList?.map(({ text, link }) => (
        <Item
          key={generateUUID()}
          to={link}
          onClick={(e) => handleClick(e, link)}
          minwidth={minWidth}
        >
          <Text>{text}</Text>
        </Item>
      ))}
    </List>
  )
}

export default Tabs
