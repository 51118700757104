/* eslint-disable react-hooks/exhaustive-deps */
import useOutsideClick from 'components/hooks/useOutsideClick'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import infoIcon from 'images/icons/info-black.svg'

const ALL = 'ALL'

const SelectContainer = styled.div`
  width: 100%;
  position: relative;

  ${({ styles }) => styles}
`

const LabelText = styled.p`
  width: fit-content;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  ${({ styles }) => styles}
`

const InfoWrap = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 8px;
`

const ImgInfo = styled.img`
  display: inline-block;
`

const InputInnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 2px;

  width: 100%;
  min-height: 36px;
  max-height: 55px;
  padding-left: 8px;
  padding-right: 44px;
  border: 1px solid #ced2d8;

  overflow: hidden;
  overflow-y: scroll;

  background: var(--white);

  ${({ isSelectedOption }) =>
    isSelectedOption
      ? 'padding-top: 5.16px; padding-bottom: 4px;'
      : 'padding-top: 8px; padding-bottom: 8px;'}

  ${(props) =>
    props.isInvalid
      ? `
      border-color: #BB4126;
      box-shadow: 0 0 0 1px #BB4126;
    `
      : `
      border-color: #CED2D8;
      box-shadow: none;
    `}

  ${({ styles }) => styles}
`

const SelectedOptionSpan = styled.span`
  display: flex;
  align-items: center;
  min-width: fit-content;
  /* height: 18px; */
  padding: 0 0 0 5px;
  margin-right: 2px;
  border-radius: 2px;

  background-color: #e6e6e6;

  font-size: 85%;

  ${({ styles }) => styles}
`

const OptionText = styled.p`
  //height: 17px;
  display: inline-block;
`

const CloseButtonWrap = styled.span`
  padding: 4px;
  margin-left: 3px;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: #ffbdad;

    & svg {
      fill: #de350b;
    }
  }

  & svg {
    vertical-align: middle;
  }
`

const Input = styled.input`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  ${({ styles }) => styles}
`

const ErrorMessage = styled.p`
  position: absolute;
  bottom: -17px;
  left: 0;

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--red);
`

const DropdownWrap = styled.div`
  position: absolute;
  top: ${({ label }) => (label ? '36px' : '8px')};
  right: 0;

  width: 30px;
  display: flex;
  justify-content: center;

  ${({ styles }) => styles}
`

const Dropdown = styled.svg`
  display: inline-block;
  fill: ${({ isShowOptions }) => (isShowOptions ? '#999999' : '#CCCCCC')};
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
  height: auto;
  cursor: pointer;

  &:hover {
    fill: #999999;
  }
`

const OptionsList = styled.ul`
  position: absolute;
  top: 100%;
  margin-top: 10px;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  z-index: 1;

  display: ${({ isShow }) => (isShow ? 'block' : 'none')};

  border-radius: 5px;
  border: 1px solid #ced2d8;
  background-color: #fff;

  ${({ styles }) => styles}
`

const OptionsItem = styled.li`
  padding: 8px 12px 8px 8px;

  color: ${({ isAllOptionSelected }) =>
    isAllOptionSelected ? 'hsl(0, 0%, 80%);' : 'inherit'};

  &:hover {
    background-color: #deebff;
  }

  ${({ styles }) => styles}
`

const Cross = styled.svg`
  position: absolute;
  display: inline-block;
  top: 8px;
  right: 28px;
  width: 18px;
  height: 18px;

  fill: #cccccc;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
  cursor: pointer;

  &:hover {
    fill: #999999;
  }

  ${({ styles }) => styles}
`

const MultiSelect = ({
  name,
  placeholder,
  options = [],
  required = false,
  info = false,
  error,
  formData,
  isReset,
  label,
  errorMessage = 'Invalid Value',

  isClearable = false,
  getOption,
  getValue,

  containerStyles,
  innerWrapperStyles,
  selectedOptionStyles,
  inputStyles,
  dropdownWrapStyles,
  itemStyles,
  listStyles
}) => {
  const [isShowOptions, setIsShowOptions] = useState(false)
  const [filter, setFilter] = useState('')

  const currentValue = formData && formData[name] ? formData[name] : []
  const currentOption = options.filter(({ value }) =>
    currentValue.includes(value)
  )

  const [selectedOption, setSelectedOption] = useState(currentOption)

  useEffect(() => {
    if (getValue && filter && filter.length > 0) getValue(filter)
  }, [filter])

  useEffect(() => {
    if (getOption) getOption(selectedOption)
  }, [selectedOption])

  useEffect(() => {
    if (isReset) setSelectedOption(currentOption)
  }, [isReset])

  const ref = useRef(null)

  const generateCSS = (elementStyles) => {
    if (!elementStyles) return

    const cssText = Object.entries(elementStyles)
      .map(([key, value]) => `${key}: ${value};`)
      .join(' ')

    return cssText
  }

  const containerCSS = generateCSS(containerStyles)
  const innerWrapperCSS = generateCSS(innerWrapperStyles)
  const selectedOptionCSS = generateCSS(selectedOptionStyles)
  const inputCSS = generateCSS(inputStyles)
  const dropdownWrapCss = generateCSS(dropdownWrapStyles)
  const listCSS = generateCSS(listStyles)
  const itemCSS = generateCSS(itemStyles)

  const open = () => setIsShowOptions(true)
  const close = () => setIsShowOptions(false)
  const toggle = () => setIsShowOptions(!isShowOptions)

  const changeWrapRef = useRef(null)
  useOutsideClick(changeWrapRef, close)

  const changeFilter = (e) => setFilter(e.currentTarget.value)

  const handleClick = (value, label) => {
    const withoutAll = selectedOption.length && selectedOption[0].value !== ALL
    const isSelected = selectedOption.includes(label) // по ходу, это бесполезная строка
    const isAllSelected = selectedOption.some(({ value }) => value === 'ALL')

    // если выбранная опция не ALL, текущая опция не выбрана, и ALL не выбрана:
    if (!((withoutAll && label === ALL) || isSelected || isAllSelected)) {
      setSelectedOption((prev) => [...prev, { value, label }])
    }

    setFilter('')
  }

  const isOptionDisabled = (label) =>
    (selectedOption[0]?.value === ALL && label !== ALL) ||
    (selectedOption.length && selectedOption[0] !== ALL && label === ALL)

  const removeOption = (option) =>
    setSelectedOption((prev) => prev.filter(({ value }) => value !== option))

  const getVisibleOptions = () => {
    const selectedLabels = selectedOption.map((option) => option.label)

    return options.filter((option) => {
      const isNotSelectedValue = !selectedLabels.includes(option.label)
      const isMatchingFilter = option.label
        .toLowerCase()
        .includes(filter.toLowerCase())

      return isNotSelectedValue && isMatchingFilter
    })
  }

  const visibleOptions = getVisibleOptions()

  const isInvalid = required && !selectedOption?.length && error[name]

  return (
    <SelectContainer
      data-testid={`${name}-container`}
      ref={changeWrapRef}
      styles={containerCSS}
    >
      {label && (
        <InfoWrap>
          <LabelText data-testid={`${name}-label`}>{`${label}${
            required ? '*' : ''
          }`}</LabelText>
          {info && <ImgInfo src={infoIcon} width="10" height="10" alt="info" />}
        </InfoWrap>
      )}
      <InputInnerWrapper
        isInvalid={isInvalid}
        isSelectedOption={!!selectedOption.length}
        styles={innerWrapperCSS}
        data-testid={`${name}-wrap`}
        onClick={open}
      >
        {selectedOption &&
          selectedOption.map(({ value, label }) => (
            <SelectedOptionSpan
              styles={selectedOptionCSS}
              data-testid={`${name}-${value}`}
              // data-value={value}
              key={value}
            >
              <OptionText>{label}</OptionText>
              <CloseButtonWrap
                data-testid={`${value}-removeOptionButton`}
                onClick={() => removeOption(value)}
              >
                <svg
                  height="14"
                  width="14"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path
                    d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                </svg>
              </CloseButtonWrap>
            </SelectedOptionSpan>
          ))}

        <Input
          data-testid={`${name}-input`}
          isSelectedValue={selectedOption}
          type="text"
          placeholder={selectedOption.length ? null : placeholder}
          inputEl={ref}
          onChange={changeFilter}
          value={filter}
          styles={inputCSS}
        />
      </InputInnerWrapper>
      {isClearable && !!selectedOption.length && (
        <Cross
          data-testid={`${name}-cross`}
          viewBox="0 0 24 24"
          onClick={() => setSelectedOption([])}
        >
          <path
            d="M7.1 18.3C6.7134 18.6866 6.0866 18.6866 5.7 18.3C5.3134 17.9134 5.3134 17.2866 5.7 16.9L10.6 12L5.7 7.1C5.3134 6.7134 5.3134 6.0866 5.7 5.7C6.0866 5.3134 6.7134 5.3134 7.1 5.7L12 10.6L16.9 5.7C17.2866 5.3134 17.9134 5.3134 18.3 5.7C18.6866 6.0866 18.6866 6.7134 18.3 7.1L13.4 12L18.3 16.9C18.6866 17.2866 18.6866 17.9134 18.3 18.3C17.9134 18.6866 17.2866 18.6866 16.9 18.3L12 13.4L7.1 18.3Z"></path>
        </Cross>
      )}
      <DropdownWrap label={label} styles={dropdownWrapCss}>
        <Dropdown
          data-testid={`${name}-dropdown`}
          onClick={toggle}
          isShowOptions={isShowOptions}
          width="20"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
        >
          <path
            d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </Dropdown>
      </DropdownWrap>

      <OptionsList
        data-testid={`${name}-list`}
        isShow={isShowOptions}
        styles={listCSS}
      >
        {visibleOptions.map(({ value, label }) => (
          <OptionsItem
            data-testid={`${name}-option`}
            data-value={value}
            key={value}
            isAllOptionSelected={isOptionDisabled(label)}
            className="unclosed"
            onClick={() => handleClick(value, label)}
            styles={itemCSS}
          >
            {label}
          </OptionsItem>
        ))}
      </OptionsList>

      <select
        data-testid={`${name}-select_hidden`}
        data-required={required}
        name={name}
        multiple
        // id="default-select" // не уверен, что айдишка нужна
        value={selectedOption.map(({ value }) => value)}
        readOnly
        style={{ display: 'none' }}
      >
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>

      {isInvalid && (
        <ErrorMessage data-testid={`${name}-error_message`}>
          {errorMessage}
        </ErrorMessage>
      )}
    </SelectContainer>
  )
}

export default MultiSelect
