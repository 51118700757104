import { useState, useRef } from 'react'

import useOutsideClick from 'components/hooks/useOutsideClick'

import styled from 'styled-components'

import crossIcon from 'images/icons/cross.svg'
import setting from 'images/icons/setting.svg'

const Wrap = styled.div`
  position: relative;
  background-color: var(--white);
  
  &:hover {
    outline: var(--hover-btns);
  }
`

const CrossWrap = styled.div`
  position: absolute;
  top: 6px;
  right: 8px;
  cursor: pointer;
`

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
`

const Modal = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 16px 32px 24px 16px;
  width: ${({ width }) => width};

  border: 1px solid var(--bright);
  background-color: var(--white);
`

const Title = styled.p`
  color: var(--primary-3, #0b1c3e);
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
`

const Settings = ({ children, width = '325px' }) => {
  const [isOpen, setIsOpen] = useState(false)

  const changeWrapRef = useRef(null)
  useOutsideClick(changeWrapRef, () => setIsOpen(false))

  return (
    <Wrap ref={changeWrapRef} data-testid="settings_wrap">
      <IconWrap onClick={() => setIsOpen(true)} data-testid="settings_toggle">
        <img src={setting} alt="setting" width="20" height="20" />
      </IconWrap>

      {isOpen && (
        <Modal width={width} data-testid="settings_modal">
          <CrossWrap onClick={() => setIsOpen(false)}>
            <img src={crossIcon} alt="cross" width="24" height="24" />
          </CrossWrap>
          <Title>Columns</Title>
          {children}
        </Modal>
      )}
    </Wrap>
  )
}

export default Settings
