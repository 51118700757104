import moment from 'moment'

import { differenceInDays, parse } from 'date-fns'

export const formatDateToString = (values, two) => {
  if (typeof values === 'string') return values

  let formatedData = null

  if (two === true) {
    formatedData = [
      moment(values[0]).format('DD-MM-YYYY'),
      moment(values[1]).format('DD-MM-YYYY')
    ]
  } else {
    formatedData = moment(values[0]).format('DD-MM-YYYY')
  }

  return formatedData
}

export const hasDifferenceGreaterThanDays = (values, days, daysChoosed) => {
  const format = 'dd-MM-yyyy'

  const dateA = typeof values[0] === 'string' ? parse(values[0], format, new Date()) : values[0]
  const dateB = typeof values[0] === 'string' ? parse(values[1], format, new Date()) : values[1]

  // const dateA = typeof values[0] === 'string' ? parseISO(values[0]) : values[0]
  // const dateB = typeof values[0] === 'string' ? parseISO(values[1]) : values[1]

  const daysDifference = Math.abs(differenceInDays(dateA, dateB))
  if (daysChoosed) return daysDifference

  if (daysDifference > days) {
    return true
  }

  return false
}

export const isoTostring = (raw_date, dataOnly) => {
  if (!raw_date || !raw_date?.length) return

  const momentObj = moment.utc(raw_date)
  // const momentObj = moment(raw_date, "DD-MM-YYYY, HH:mm:ss", true)

  const formattedDate = dataOnly
    ? momentObj.format('DD.MM.YYYY')
    : momentObj.format('DD.MM.YYYY') + '<br>' + momentObj.format('HH:mm:ss')

  return <div dangerouslySetInnerHTML={{ __html: formattedDate }} />
}
