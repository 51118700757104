export const removeValueFromParam = (paramName, valueToRemove) => {
  const params = new URLSearchParams(window.location.search)

  // Получаем текущие значения параметра
  const currentValues = params.getAll(paramName)

  // Фильтруем значения, исключая то, которое нужно удалить
  const updatedValues = currentValues.filter((value) => value !== valueToRemove)

  // Обновляем параметр
  const KEYS_GB = ['first_group', 'second_group', 'third_group', 'fourth_group', 'fifth_group']

  if (KEYS_GB.includes(paramName)) {
    // Получаем индекс paramName в KEYS_GB
    const startIndex = KEYS_GB.indexOf(paramName)

    // Удаляем ключи начиная с paramName
    KEYS_GB.slice(startIndex).forEach(key => {
      params.delete(key)
    })
  } else {
    params.delete(paramName)
  }

  updatedValues.forEach((value) => params.append(paramName, value))
  if (paramName === 'date_from') params.delete('date_to')
  if (paramName === 'date_to') params.delete('date_from')

  // Устанавливаем обновленные параметры в адресную строку
  window.location.replace(`${window.location.pathname}?${params.toString()}`)
}

export const getQueryParams = (shoudBeArr = []) => {
  const params = {}

  for (const [key, value] of new URLSearchParams(window.location.search)) {
    if (!params[key]) {
      if (shoudBeArr.some((el) => el === key)) {
        params[key] = [value]
      } else {
        params[key] = value
      }
    } else if (Array.isArray(params[key])) {
      params[key].push(value)
    } else {
      params[key] = [params[key], value]
    }
  }

  return params
}
