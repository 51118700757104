import styled from 'styled-components'
import { TD as Base_td, TH as base_th } from 'components/common/baseStyled'
import { device } from 'components/common/constants/device'

export const TableWrap = styled.div`
  & td:not(:first-child) {
    text-align: center;
  }

  @media ${device.mobile} {
    margin-left: 16px;
  }
`

export const TH = styled(base_th)`
  text-align: ${({ align }) => (align ? align : 'center')};
`
export const TD = styled(Base_td)`
  background: ${({ background }) => `var(--table-lvl-${background})`};
`

export const ArrowWrap = styled.span`
  padding-left: 8px;
  padding-right: 8px;

  cursor: pointer;
`

export const ArrowDown = styled.img`
  transform: ${({ rotate }) => (rotate === 'true' ? 'rotate(-180deg)' : '')};
`

export const KEY_LIST = 'lilist'

export const KEYS_GB = {
  1: 'first_group',
  2: 'second_group',
  3: 'third_group',
  4: 'fourth_group',
  5: 'fifth_group'
}

export const KEYS_VALUES = {
  1: 'value',
  2: 'second_value',
  3: 'third_value',
  4: 'fourth_value',
  5: 'fifth_value'
}

export const LVL_LIMIT = 5

export const getParams = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const params = {}
  for (const [key, value] of urlParams) {
    if (!params[key]) {
      params[key] = value
    } else if (Array.isArray(params[key])) {
      params[key].push(value)
    } else {
      params[key] = [params[key], value]
    }
  }

  return params
}

export const arrayToObjectPath = (array, obj) => {
  //Create object paths
  let current = obj

  array.forEach((key, index) => {
    if (!current[key]) {
      current[key] = {}
    }
    if (index === array.length - 1) {
      current[key] = {}
    }
    current = current[key]
  })
}

export const getNestedValue = (obj, keys, keyy = 'list') => {
  //Get a key from the end of keys path
  return keys.reduce((current, key) => {
    if (current && current[key] !== undefined) {
      return current[key]
    }
    return undefined
  }, obj)?.[keyy]
}

export const setNestedValue = (obj, keys, value, keyy = 'list') => {
  //Set a value in the end of keys path
  keys.reduce((current, key, index) => {
    if (index === keys.length - 1) {
      current[key][keyy] = value
    } else {
      current[key] = current[key] || {}
    }
    return current[key]
  }, obj)
}

export const removeKeysDeep = (obj, keysToRemove) => {
  //Delete all keysToRemove[] in obj into all deep
  if (Array.isArray(obj)) {
    return obj.map(item => removeKeysDeep(item, keysToRemove)) // Обрабатываем элементы массива
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj = {} // Создаем новый объект для того, чтобы не изменять исходный
    for (let key in obj) {
      if (keysToRemove.includes(key)) {
        continue // Пропускаем ключи, которые должны быть удалены
      }
      newObj[key] = removeKeysDeep(obj[key], keysToRemove) // Рекурсивно обрабатываем вложенные объекты
    }
    return newObj
  }
  return obj // Если не объект и не массив, возвращаем значение как есть
}


export const checkNestedLevel = (obj, prevIds) => {
  //Check is prevIds[] exit
  return prevIds.every((id) => {
    if (obj && obj[id]) {
      obj = obj[id]
      return true
    }
    return false
  })
}


export const removeLastKey = (obj, prevIds) => {
  //remove last key in prevIds[] path
  if (prevIds.length === 0) return

  let current = obj
  for (let i = 0; i < prevIds.length - 1; i++) {
    // Если на пути нет объекта, возвращаем
    if (!current[prevIds[i]]) return
    current = current[prevIds[i]]
  }

  // Удаляем последний ключ, если он существует
  const lastKey = prevIds[prevIds.length - 1]
  if (current[lastKey] !== undefined) {
    delete current[lastKey]
  }
}

export const buildQueryString = (params) => {
  const queryParts = []

  const encode = (key, value) => {
    // Если значение массив, добавляем каждый элемент как отдельный параметр
    if (Array.isArray(value)) {
      value.forEach((item) => {
        queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
      })
    }
    // Если значение объект, сериализуем в JSON
    else if (typeof value === 'object' && value !== null) {
      queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    }
    // Для простых типов данных
    else {
      queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    }
  }

  // Обрабатываем все параметры
  for (const [key, value] of Object.entries(params)) {
    encode(key, value)
  }

  return queryParts.join('&')
}

export const redirectWithParams = ({ status, prevIds, queryParams, path }) => {
  const defaultGb = 'affiliate'
  let base = `${path}?`

  let iterator = 1
  for (let key of prevIds) {
    const currentKey = queryParams.get(KEYS_GB[iterator])

    if (currentKey === 'date') {
      base += `&date_from=${key}&date_to=${key}`
    } else if (currentKey === 'advertiser_manager') {
      base += `&acc_manager=${key}`
    } else if (currentKey === 'affiliate_manager') {
      base += `&aff_manager=${key}`
    } else {
      base += `&${currentKey ?? defaultGb}=${key}`
    }

    iterator += 1
  }

  if (queryParams.get('date_from') && queryParams.get('date_to')) {
    const regex = /&date_from=\d{2}-\d{2}-\d{4}&date_to=\d{2}-\d{2}-\d{4}/
    const hasDateRange = regex.test(base)

    if (!hasDateRange) base += `&date_from=${queryParams.get('date_from')}&date_to=${queryParams.get('date_to')}`
  }

  if (status) {
    return `${base}&status=${status}`
  } else {
    return base
  }
}