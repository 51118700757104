import { generateUUID } from 'components/common/utils/helpers'
import { filterEmptyObj } from 'components/common/utils/formHelpers'
import { Text } from 'components/Shared'

import { getParams } from 'components/common/utils/RecursiveUtils'
import { removeValueFromParam } from 'components/common/utils/query'

import styled from 'styled-components'
import { device } from 'components/common/constants/device'

import crossIcon from 'images/icons/cross.svg'

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding-right: ${({ paddingRight }) => paddingRight};

  @media ${device.mobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`

const Clear = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 4px;
  margin-left: ${({ ml }) => ml && 'auto'};
  cursor: ${({ ml }) => ml && 'pointer '};

  background: #d7dff6;
`

const SearchList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
  max-height: 100px;
`

const SearchItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 4px;
  margin-left: ${({ ml }) => ml && 'auto'};
  cursor: ${({ ml }) => ml && 'pointer '};

  background: #d7dff6;
`

const ButtonCross = styled.button`
  width: 16px;
  height: 16px;
  background: inherit;
`

const black_list = [
  'lilist',
  'first_group',
  'second_group',
  'third_group',
  'fourth_group',
  'fifth_group',
  'value',
  'second_value',
  'third_value',
  'fourth_value',
  'count',
  'page',
  'sort'
]

export const filterSearchList = ({
  search,
  search_value,
  setSearchActive,
  searchActive,
  newform,
  split
}) => {
  let NewObj = null

  if (search && search_value) {
    const key = newform ? search : search?.value
    const splitValues = search_value.trim().split(/\s+/)

    setSearchActive((prev) => ({
      ...prev,
      [key]: [...(prev[key] || []), ...(split ? splitValues : [search_value])]
    }))

    NewObj = {
      [key]: [
        ...(searchActive[key] || []),
        ...(split ? splitValues : [search_value])
      ]
    }
  }

  const searchFilters = filterEmptyObj({
    ...searchActive,
    ...(NewObj && { ...NewObj })
  })

  return searchFilters
}

const ActivesSearchList = () => {
  const params = getParams()

  const searchList = []

  const filterValues = (key) => {
    return !black_list.includes(key)
  }

  for (let key in params) {
    if (params[key] && filterValues(key)) {
      typeof params[key] === 'object'
        ? params[key].filter(
          (el) =>
            el !== 'undefined' && searchList.push({ text: key, value: el })
        )
        : params[key] !== 'undefined' &&
        searchList.push({ text: key, value: params[key] })
    }
  }

  return (
    <Wrap data-testid="actives-search-list">
      {!!searchList?.length && (
        <>
          <SearchList className="scroll">
            {searchList?.map(({ text, value }) => (
              <SearchItem key={generateUUID()} data-testid="search-item">
                <Text text={`${text}:`} /> <Text text={value} weight="700" />
                <ButtonCross
                  onClick={() => removeValueFromParam(text, value)}
                  data-testid="close-button"
                >
                  <img src={crossIcon} alt="cross" width="16" height="16" />
                </ButtonCross>
              </SearchItem>
            ))}
          </SearchList>
          <div>
            <Clear
              ml
              pointer
              onClick={() => window.location.replace(window.location.pathname)}
            >
              <Text data-testid="clearBtn" text="Clear&nbsp;All" weight="700" />
            </Clear>
          </div>
        </>
      )}
    </Wrap>
  )
}

export default ActivesSearchList
