export default class Routes {
  static PARAM_SPLITTER = /:\w+/g

  static dashboard = {
    index: '/dashboard'
  }

  static roles = {
    index: '/roles',
    affiliate: {
      index: '/roles/affiliate',
      create: '/roles/affiliate/create',
      edit: '/roles/affiliate/edit/:uuid'
    },
    advertiser: {
      index: '/roles/advertiser',
      create: '/roles/advertiser/create',
      edit: '/roles/advertiser/edit/:uuid'
    },
    manager: {
      index: '/roles/manager',
      create: '/roles/manager/create',
      edit: '/roles/manager/edit/:uuid'
    }
  }
// Routes.getUrl(Routes.coldApi.leadList.index, { uuid })
  static coldApi = {
    index: '/cold-api',
    reColdApi: {
      index: '/cold-api/re-cold-api',
      list: '/cold-api/re-cold-api/list/:uuid'
    },
    rules: '/cold-api/rules',
    leadList: {
      index: '/cold-api/lead-list',
      add: {
        index: '/cold-api/lead-list/add',
        userSession: '/cold-api/lead-list/add/user-session/:uuid',
        conversion: '/cold-api/lead-list/add/conversion/:uuid'
      },
      details: {
        index: '/cold-api/lead-list/details',
        userSession: '/cold-api/lead-list/details/user-session/:uuid',
        conversion: '/cold-api/lead-list/details/conversion/:uuid'
      }
    }
  }

  static billing = {
    index: '/billing',
    invoice: {
      create: '/billing/invoice-create',
      edit: '/billing/invoice-edit/:uuid',
      delete: '/billing/invoice-delete/:uuid',
      detail: '/billing/invoice-detail/:uuid'
    }
  }

  static offer = {
    index: '/offers',
    direct: {
      create: '/offers/direct-link/create',
      edit: '/offers/direct-link/edit/:uuid',
      detail: '/offers/direct-link/detail/:uuid'
    },
    api: {
      create: '/offers/api/create',
      edit: '/offers/api/edit/:uuid',
      detail: '/offers/api/detail/:uuid'
    }
  }

  static statistic = {
    index: '/cold-api',
    postbacks: '/statistic/postbacks',
    direct: {
      general: '/statistic/direct-link/general',
      clicks: '/statistic/direct-link/clicks',
      conversion: '/statistic/direct-link/conversion'
    },
    api: {
      general: '/statistic/api/general',
      clicks: '/statistic/api/clicks',
      userSession: '/statistic/api/user-session',
      conversion: '/statistic/api/conversion'
    }
  }

  static getUrl(path, params = {}) {
    const dynamicKeys = path.match(Routes.PARAM_SPLITTER) || []

    const missingKeys = dynamicKeys.filter(
      (key) => !Object.keys(params).includes(key.slice(1))
    )

    if (missingKeys.length > 0) {
      console.warn(
        `Missing parameters for URL generation: ${missingKeys.map((key) => key.slice(1)).join(', ')}`
      )

      return '/'
    }

    return dynamicKeys.reduce(
      (url, key) => url.replace(key, params[key.slice(1)] || key),
      path
    )
  }
}

/*
// Корректный случай
const editUrl = Routes.getUrl(Routes.offer.direct.edit, { uuid: '12345' });
console.log(editUrl); // "/offer/direct/edit/12345"

// Ошибка: отсутствуют параметры
const invalidUrl = Routes.getUrl(Routes.offer.api.edit);
console.log(invalidUrl); // "/error" + предупреждение в консоль
*/
